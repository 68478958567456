import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import _ from 'xe-utils';
import ProductList from './product-list.vue';
import CustomerList from './customer-list.vue';

formCreate.component('ProductList', ProductList);
formCreate.component('CustomerList', CustomerList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'DaShan_budget_rules_form',
      costRulesTierList: [],
      buttons: {
        submit: this.formConfig.type !== 'view',
      },
    };
  },

  methods: {
    // 设置规则
    setRule(item) {
      const v = item;
      if (v.field === 'productName') { // 选择产品
        v.props = {
          ...v.props,
          params: {
            functionCode: 'product_list',
            paramData: {
              enableStatus: '009',

            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'transitDealerName') { // 选择大商
        v.props = {
          ...v.props,
          params: {
            functionCode: 'custmoer_list',
            paramData: {
              ext35: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
      }
      if (v.field === 'startTime') {
        v.props = {
          ...v.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: '00:00:00',
        };
      }
      if (v.field === 'endTime') {
        v.props = {
          ...v.props,
          type: 'datetime',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: '23:59:59',
        };
      }
      if (v.field === 'price') {
        v.props = {
          ...v.props,
          min: 0,
          precision: 2,
          controls: false,
        };
      }
      return item;
    },
    // 完成渲染
    formComplete() {
      const { type } = this.formConfig;

      const productName = this.getRule('productName');
      productName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            productName: e[0].materialName,
            productCode: e[0].materialCode,
          });
        } else {
          this.setValue({
            productName: null,
            productCode: null,
          });
        }
      };
      // 大商
      const transitDealerName = this.getRule('transitDealerName');
      transitDealerName.on.change = (e) => {
        if (e && e.length > 0) {
          this.setValue({
            transitDealerName: e[0].customerName,
            transitDealerCode: e[0].customerCode,
          });
        } else {
          this.setValue({
            transitDealerName: null,
            transitDealerCode: null,
          });
        }
      };
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        if (new Date(formData.startTime) > new Date(formData.endTime)) {
          this.$message.error('开始时间不能大于结束时间');
          return;
        }
        // 默认新增
        const url = '/dms/dmstransitpricerule/save';
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
