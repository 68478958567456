<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form';

export default {
  name: 'DaShan_budget_rules_list',
  extends: TablePage,
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  components: {
    Form,
  },
  methods: {
    // 按钮点击事件
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.formConfig = {
          type: val.code,
        };
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('DaShan_budget_rules_list');
  },
};
</script>
